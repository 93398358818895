<template>
  <layout class="main-cols">
    <div
      class="columns is-multiline h-full items-center flex-row justify-center h-85vh"
    >
      <div class="column is-6">
        <b-image class="is-6" src="/images/error404.png" alt="" />
      </div>
      <div class="column is-12 mt-8">
        <p class="has-text-primary text-4xl font-bold">Página no encontrada</p>
        <p>
          Es probable que esta página haya sido removida.
        </p>
        <p>Disculpe las molestias.</p>
      </div>
      <div class="column is-4">
        <b-button
          type="is-primary"
          expanded
          @click="$router.back(1)"
          >Volver</b-button
        >
      </div>
    </div>
  </layout>
</template>

<script>
export default {
  name: "Error404"
};
</script>
